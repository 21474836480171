const theme = {
  breakpoints: ['31em', '50em', '91em', '121em'],
  fontSizes: [
    12, 14, 16, 20, 24, 32, 48, 64, 72, 84, 96, 108, 124, 136, 148, 160, 180,
  ],
  colors: {
    blue: '#07c',
    lightgray: '#f6f6ff',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: 'graphie, sans-serif',
    bodyA: 'Neutra Text, sans-serif',
    bodyB: 'Azo Sans, sans-serif',
    heading: 'Sofia Pro, sans-serif',
    subheading: 'Arboria, sans-serif',
  },
  fontWeights: {
    body: 400,
    bodyA: 400,
    bodyB: 400,
    lighter: 'lighter',
    bold: 'bold',
  },
  lineHeights: {
    body: 1.5,
    bodyA: 1.5,
    bodyB: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  text: {
    heading: {
      fontFamily: 'heading',
      fontWeight: '300',
      marginLeft: [null, null, -2, null],
      fontSize: ['4rem', '5rem', '8rem', '10rem', '12rem'],
    },
    subheading: {
      fontFamily: 'subheading',
      fontWeight: 'lighter',
      fontSize: ['0.8em', '1em', '1.16em', '1.44em', 5],
    },
    title: {
      fontFamily: 'bodyB',
      fontWeight: 300,
      fontSize: [5, null, null, 6],
    },
    subtitle: {
      fontFamily: 'bodyB',
      fontWeight: 400,
      fontSize: [3, 4, null, null],
      paddingBottom: [1, 2, null, null],
    },
    body: {
      fontFamily: 'body',
      fontWeight: 'body',
      fontSize: [1, 2, 3, '1.25rem'],
      textAlign: 'justify',
    },
    bodyA: {
      fontFamily: 'bodyA',
      fontWeight: 'bodyA',
      fontSize: [1, 2, '1.3rem', '1.5rem'],
      lineHeight: ['1rem', '1.6rem', null, null],
      textAlign: 'justify',
    },
    bodyB: {
      fontFamily: 'bodyB',
      fontWeight: 'bodyB',
      fontSize: [1, 2, null, null, 3],
      lineHeight: ['1rem', '1.6rem', null, null, '2rem'],
      textAlign: 'justify',
    },
  },
  buttons: {
    primary: {
      color: 'white',
      bg: 'primary',
    },
  },
};

export default theme;
